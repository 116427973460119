import React from "react";
import { Link } from "gatsby"
import TitleHeader from "../components/TitleHeader";

import Layout from "../components/layout"
// import SEO from "../components/seo"

const Uslugi = () => {
  return (
    <Layout>
      <div className="uslugi">
        <TitleHeader title="Usługi" />
  
        <div className="content">
          <div className="choose-service">
            <Link className="border-a" to="/uslugi/stomatologia-estetyczna">
              Stomatologia estetyczna
            </Link>
            <Link className="border-a" to="/uslugi/implantologia">
              Implantologia
            </Link>
            <Link className="border-a" to="/uslugi/kompleksowa-opieka">
              Kompleksowa opieka
              <br />
              stomatologiczna
            </Link>
            <Link className="border-a" to="/uslugi/medycyna-estetyczna">
              Medycyna estetyczna
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Uslugi;
